import cert1 from "../../Assets/Cert 1.png";
import cert2 from "../../Assets/Cert 3.png";
import cert3 from "../../Assets/Cert 2.png";
import cert4 from "../../Assets/Cert 4.png";
import cert5 from "../../Assets/Cert 5.png";
import cert6 from "../../Assets/Cert 6.png";
import cert7 from "../../Assets/cert 7.jpg";

const CertData = [
  {
    link: "https://tryhackme-certificates.s3-eu-west-1.amazonaws.com/THM-PHXKCCDAOD.pdf",
    CertImage: cert7,
    title: "Jr Penetration Tester",
    data: "Awarded Jan 2025",
    CertLink: "https://tryhackme-certificates.s3-eu-west-1.amazonaws.com/THM-PHXKCCDAOD.pdf",
    info: "This learning path covers the core technical skills that will allow you to succeed as a junior penetration tester. Upon completing this path, you will have the practical skills necessary to perform security assessments against web applications and enterprise infrastructure.",
  },
  {
    link: "https://www.credly.com/badges/6753d54b-7c84-4134-8f1e-7dc11746de3c/linked_in_profile",
    CertImage: cert6,
    title: "Introduction to Cybersecurity",
    data: "Awarded May 2024",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "Cisco verifies the earner of this badge successfully completed the Introduction to Cybersecurity course. The holder of this student-level credential has introductory knowledge of cybersecurity, including the global implications of cyber threats on industries, and why cybersecurity is a growing profession. They understand vulnerabilities and threat detection and defense. They also have insight into opportunities available with pursuing cybersecurity certifications.",
  },
  {
    link: "https://www.credly.com/badges/fcc0ce5b-edc2-4fc5-877e-f404257610a9/public_url",
    CertImage: cert5,
    title: "Meta Front-End Developer Certificate",
    data: "Awarded Dec. 2023",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "The Meta Front-End Developer Certificate is awarded to learners who complete a series of courses on front-end web development, hosted by Coursera. With a focus on React.js, these rigorous, self-paced courses, developed by Meta experts, prepare a candidate for an entry level job as a front-end developer. The courses culminate in the successful completion of a capstone project.",
  },
  {
    link: "https://www.credly.com/badges/bd74b796-17a2-4b44-95f7-bd65138a2cb5",
    CertImage: cert2,
    title: "Meta iOS Developer Certificate",
    data: "Awarded March 2023",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "The Meta iOS Developer Certificate is awarded to learners who complete a series of courses on iOS mobile development, hosted by Coursera. With a focus on React Native and Swift, these rigorous, self-paced courses, developed by Meta experts, prepare a candidate for an entry level job as an iOS developer. The courses culminate in the successful completion of a capstone project.",
  },
  {
    link: "https://www.credly.com/badges/856f7596-786a-4430-be3e-7740dcf476b5",
    CertImage: cert1,
    title: "AWS Certified Cloud Practitioner",
    data: "Awarded August 2022",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "Arners of this certification have a fundamental understanding of IT services and their uses in the AWS Cloud. They demonstrated cloud fluency and foundational AWS knowledge. Badge owners are able to identify essential AWS services necessary to set up AWS-focused projects.Earners of this certification have a fundamental understanding  of IT services and their uses in the AWS Cloud.",
  },
  {
    link: "https://coursera.org/verify/PKE7C3LN6K5H",
    CertImage: cert3,
    title: "Principles of UX /UI Design",
    data: "Awarded March 2023",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "The UI Figma Certificate is awarded to learners on the principles of UI / UX Designs using Figma",
  },
  {
    link: "https://coursera.org/share/3d35431a6ab9e7ffce3d494eff194d12",
    CertImage: cert4,
    title: "Meta React Native",
    data: "Awarded April 2023",
    CertLink: "https://my-keeper.github.io/notesk/#/calendar",
    info: "The Meta React Native Certificate is awarded to learners who complete a series of courses on React Native, hosted by Coursera.",
  },
];
export default CertData;
